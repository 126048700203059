<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title class="font-weight-semibold">
        Top de Autores
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errMessage }}
      </v-alert>
      <div
        v-if="!loading && data.length > 0"
        style="max-height:600px; overflow:auto;"
      >
        <v-list
          subheader
          two-line
        >
          <v-list-item
            v-for="d in data"
            :key="d.username"
            :color="resolveStatisticsIconVariation (d.red).color"
          >
            <v-list-item-avatar color="grey darken-3">
              <v-img
                v-if="d.avatar"
                class="elevation-6"
                alt=""
                :src="d.avatar"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="d.username"></v-list-item-title>

              <v-list-item-subtitle v-text="d.total + ' Post'"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                target="_blank"
                :href="d.link"
                icon
              >
                <v-icon :color="resolveStatisticsIconVariation (d.red).color">
                  {{ resolveStatisticsIconVariation (d.red).icon }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  mdiFacebook,
  mdiTwitter,
  mdiInstagram,
  mdiYoutube,
  mdiRss,
  mdiNewspaper,
  mdiReddit,
  mdiBulletinBoard,
} from '@mdi/js'

export default {
  data() {
    return {
      data: [],
      loading: true,
      error: false,
      errMessage: '',
      selectedSentimiento: 'Positivo',
      items: ['Positivo', 'Neutro', 'Negativo'],
    }
  },
  watch: {
    selectedSentimiento(sentimiento) {
      this.filterData(sentimiento)
    },
  },
  mounted() {
  },
  methods: {
    setData(data) {
      this.data = data
      this.loading = false
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      const dataCo = {
        facebook: { icon: mdiFacebook, color: '#1877f2' },
        facebook_public: { icon: mdiFacebook, color: '#1877f2' },
        twitter: { icon: mdiTwitter, color: '#1da1f2' },
        instagram: { icon: mdiInstagram, color: '#c32aa3' },
        youtube: { icon: mdiYoutube, color: '#ff0000' },
        blog: { icon: mdiRss, color: '#35465d' },
        news: { icon: mdiNewspaper, color: 'primary' },
        reddit: { icon: mdiReddit, color: '#ff4500' },
        board: { icon: mdiBulletinBoard, color: '#a6b1b7' },
      }

      return dataCo[data]
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiFacebook,
        mdiTwitter,
        mdiInstagram,
        mdiYoutube,
        mdiRss,
        mdiNewspaper,
        mdiReddit,
        mdiBulletinBoard,
      },
    }
  },
}
</script>
