<template>
  <v-card
    class="mx-auto mb-4 "
    :color="resolveStatisticsIconVariation (red).color"
    dark
  >
    <v-card-title class="d-flex justify-space-between">
      <div>
        <v-icon
          color="white"
          x-large
          left
        >
          {{ resolveStatisticsIconVariation (red).icon }}
        </v-icon>
        <span class="text-h6 font-weight-light white--text">{{ red | capitalize }}</span>
      </div>
      <v-chip
        class="ma-2"
        :color="sentimientoColor"
      >
        {{ sentimiento }}
      </v-chip>
    </v-card-title>

    <v-card-text
      style="max-height:200px; overflow:auto;"
      class="white--text"
    >
      {{ contenido }}
    </v-card-text>

    <v-card-actions>
      <v-list-item class="grow">
        <v-list-item-avatar color="grey darken-3">
          <v-img
            v-if="avatar"
            class="elevation-6"
            alt=""
            :src="avatar"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ username }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ fecha | formatDate }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  mdiFacebook,
  mdiTwitter,
  mdiInstagram,
  mdiYoutube,
  mdiRss,
  mdiNewspaper,
  mdiReddit,
  mdiBulletinBoard,
} from '@mdi/js'

export default {
  props: {
    red: {
      type: String,
      default: '',
    },
    contenido: {
      type: String,
      default: '',
    },
    sentimiento: {
      type: String,
      default: '',
    },
    fecha: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },

  },
  data: () => ({
    sentimientoColor: '',
  }),
  mounted() {
    const colorCat = {
      Positivo: '#4CAF50',
      Neutro: '#F5F5F5',
      Negativo: '#E53935',
    }

    this.sentimientoColor = colorCat[this.sentimiento]
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      const dataCo = {
        facebook: { icon: mdiFacebook, color: '#1877f2' },
        facebook_public: { icon: mdiFacebook, color: '#1877f2' },
        twitter: { icon: mdiTwitter, color: '#1da1f2' },
        instagram: { icon: mdiInstagram, color: '#c32aa3' },
        youtube: { icon: mdiYoutube, color: '#ff0000' },
        blog: { icon: mdiRss, color: '#35465d' },
        news: { icon: mdiNewspaper, color: 'primary' },
        reddit: { icon: mdiReddit, color: '#ff4500' },
        board: { icon: mdiBulletinBoard, color: '#a6b1b7' },
      }

      return dataCo[data]
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiFacebook,
        mdiTwitter,
        mdiInstagram,
        mdiYoutube,
        mdiRss,
        mdiNewspaper,
        mdiReddit,
        mdiBulletinBoard,
      },
    }
  },
}
</script>
