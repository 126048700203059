<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title class="font-weight-semibold">
        Sentimientos <br>Redes Sociales
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        v-model="selectedSentimiento"
        :items="items"
        class="mt-6 font-weight-bold"
        dense
      ></v-select>
    </v-toolbar>
    <p></p>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errMessage }}
      </v-alert>
      <div
        v-if="!loading && currentData.length > 0"
        style="max-height:600px; overflow:auto;"
      >
        <social-card
          v-for="dato of currentData"
          :key="dato.contenido"
          :red="dato.red"
          :contenido="dato.contenido"
          :sentimiento="dato.sentimiento"
          :avatar="dato.autor.avatar"
          :username="dato.autor.username"
          :fecha="dato.fecha_creacion"
        ></social-card>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import SocialCard from './SocialCard'

export default {
  components: { SocialCard },
  data() {
    return {
      data: [],
      currentData: [],
      loading: true,
      error: false,
      errMessage: '',
      selectedSentimiento: 'Positivo',
      items: ['Positivo', 'Neutro', 'Negativo'],
    }
  },
  watch: {
    selectedSentimiento(sentimiento) {
      this.filterData(sentimiento)
    },
  },
  mounted() {
  },
  methods: {
    setData(data) {
      this.data = data
      this.loading = false
      this.filterData(this.selectedSentimiento)
    },
    filterData(sentimiento) {
      this.currentData = this.data.filter(d => d.sentimiento === sentimiento)
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
}
</script>
