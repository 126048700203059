import axios from 'axios'
import environment from '@/environments/environment'
import auth from './auth'

const path = environment.apiUrl

export default {
  getRedes(params) {
    return axios.post(`${path}/redes`, params, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    })
  },
}
