<template>
  <v-card :loading="loading">
    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title | capitalize }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import {
  mdiFacebook,
  mdiTwitter,
  mdiInstagram,
  mdiYoutube,
  mdiRss,
  mdiNewspaper,
  mdiReddit,
  mdiBulletinBoard,
} from '@mdi/js'

export default {
  data() {
    return {
      loading: true,
      statisticsData: [
        {
          title: 'facebook',
          total: 0,
        },
        {
          title: 'twitter',
          total: 0,
        },
        {
          title: 'instagram',
          total: 0,
        },
        {
          title: 'youtube',
          total: 0,
        },
        {
          title: 'blog',
          total: 0,
        },
        {
          title: 'news',
          total: 0,
        },
        {
          title: 'reddit',
          total: 0,
        },
        {
          title: 'board',
          total: 0,
        },
      ],
    }
  },
  mounted() {

  },
  methods: {
    setData(redData) {
      this.statisticsData.forEach((dato, i) => {
        if (dato.title in redData) {
          this.statisticsData[i].total = redData[dato.title]
        }
      })
      this.loading = false
    },
  },
  setup() {
    const resolveStatisticsIconVariation = redname => {
      const dataCo = {
        facebook: { icon: mdiFacebook, color: '#1877f2' },
        twitter: { icon: mdiTwitter, color: '#1da1f2' },
        instagram: { icon: mdiInstagram, color: '#c32aa3' },
        youtube: { icon: mdiYoutube, color: '#ff0000' },
        blog: { icon: mdiRss, color: '#35465d' },
        news: { icon: mdiNewspaper, color: 'primary' },
        reddit: { icon: mdiReddit, color: '#ff4500' },
        board: { icon: mdiBulletinBoard, color: '#a6b1b7' },
      }

      return dataCo[redname]
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiFacebook,
        mdiTwitter,
        mdiInstagram,
        mdiYoutube,
        mdiRss,
        mdiNewspaper,
        mdiReddit,
        mdiBulletinBoard,
      },
    }
  },
}
</script>
